import { styled, TextField } from "@mui/material";
import { CalendarPickerView, LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { MeasureCalculationGranularity } from "api-shared";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import { useCallback, useMemo, useState } from "react";
import { calendarToFiscal, fiscalToCalendar, makeAdapterFiscalMoment } from "../../../lib/fiscal-units";
import { noOp } from "../../../lib/utils";

// StaticDatePicker className does not work, see: https://github.com/mui/mui-x/issues/4942
// As a workaround, use a container to generate and apply the styles
const CustomPickerStyles = styled("div")(({ theme }) => ({
    "& .PrivatePickersYear-yearButton": {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        width: "unset", // fixed default width is too small for fiscal quarter/year labels
    },
}));

interface ICalculationTimerangeDialogProps {
    defaultValue?: Moment | null;
    min?: Moment;
    max?: Moment;
    updateValue: (newValue: Moment) => void;
    granularity: MeasureCalculationGranularity;
    fiscalYearStart: number;
    translate: TFunction;
}

const CalculationTimerangeDialog = ({
    defaultValue = null,
    updateValue,
    granularity,
    fiscalYearStart,
    min: minProps,
    max: maxProps,
    translate,
}: ICalculationTimerangeDialogProps) => {
    // hold value here, not in upper components to avoid fully re-rendering the table when selection not yet finished
    const [calendarValue, setCalendarValue] = useState(defaultValue);

    // When the granularity should display fiscal values, transform all given moments into fiscal ones
    const isFiscal = granularity !== MeasureCalculationGranularity.MONTH;
    const fiscalValue =
        isFiscal && calendarValue != null ? calendarToFiscal(calendarValue, fiscalYearStart, granularity).fiscalMoment : calendarValue;
    const min = isFiscal && minProps != null ? calendarToFiscal(minProps, fiscalYearStart, granularity).fiscalMoment : minProps;
    const max = isFiscal && maxProps != null ? calendarToFiscal(maxProps, fiscalYearStart, granularity).fiscalMoment : maxProps;

    const views: CalendarPickerView[] = granularity === MeasureCalculationGranularity.FISCAL_YEAR ? ["year"] : ["year", "month"];

    const AdapterFiscalMoment = useMemo(
        () => makeAdapterFiscalMoment(fiscalYearStart, granularity, translate),
        [fiscalYearStart, granularity, translate],
    );

    const updateFromPicker = useCallback(
        (newValue: Moment | null, isFinish = true) => {
            if (newValue == null) {
                return;
            }
            const newCalendarValue = isFiscal ? fiscalToCalendar(newValue, fiscalYearStart) : newValue;
            setCalendarValue(newCalendarValue);
            if (isFinish) {
                updateValue(newCalendarValue);
            }
        },
        [isFiscal, fiscalYearStart, updateValue],
    );

    const updateYear = useCallback(
        (newValue: moment.Moment | null): void => updateFromPicker(newValue, granularity === MeasureCalculationGranularity.FISCAL_YEAR),
        [updateFromPicker, granularity],
    );

    return (
        // Unfortunately, @mui/x-date-pickers do not support quarters as unit
        // Workaround: Use customized AdapterMoment, that essentially override all monthly behaviour with quarters
        <LocalizationProvider dateAdapter={AdapterFiscalMoment}>
            <CustomPickerStyles>
                <StaticDatePicker<Moment>
                    autoFocus // scroll current month/quarter/year into view when opening
                    value={fiscalValue}
                    renderInput={(props) => <TextField {...props} />}
                    // onChange is mandatory, but component logic relies on onMonthChange/onYearChange
                    onChange={noOp}
                    onMonthChange={updateFromPicker}
                    onYearChange={updateYear}
                    minDate={min}
                    maxDate={max}
                    openTo={views[views.length - 1]}
                    views={views}
                    componentsProps={{
                        actionBar: {
                            // Do not show anything inside of the action bar
                            actions: [],
                        },
                    }}
                    showToolbar={false}
                />
            </CustomPickerStyles>
        </LocalizationProvider>
    );
};

export default CalculationTimerangeDialog;

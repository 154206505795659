import TrendingFlatIcon from "@mui/icons-material/TrendingFlatRounded";
import { alpha, Popover, styled, Tab, tabClasses } from "@mui/material";
import MuiTabs, { TabsProps as MuiTabsProps } from "@mui/material/Tabs";
import { MaxYear, MeasureCalculationGranularity, MinYear } from "api-shared";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { calendarToFiscal, formatFiscalUnit } from "../../../lib/fiscal-units";
import { translationKeys } from "../../../translations/main-translations";
import Tooltip from "../../Tooltip";
import CalculationTimerangeDialog from "./CalculationTimerangeDialog";

// https://github.com/mui/material-ui/pull/38717
const Tabs = React.forwardRef<HTMLDivElement, MuiTabsProps>((props, ref) => {
    return <MuiTabs ref={ref} {...props} />;
});

const RootTabs = styled(Tabs)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.border.main}`,
    minHeight: 0,
}));

const DateTab = styled(Tab, { shouldForwardProp: (name) => name !== "dense" && name !== "isPlaceholder" })<{
    dense?: boolean;
    isPlaceholder?: boolean;
}>(({ theme, dense, isPlaceholder }) => ({
    minWidth: 0,
    minHeight: 0,
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 0),
    opacity: 1,
    "&:first-of-type, &:last-of-type": {
        padding: theme.spacing(1, 1),
    },
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
    },
    "&.Mui-selected": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    },
    ...(dense && {
        ...theme.typography.body2,
        padding: theme.spacing(0.5, 0),
        "&:first-of-type, &:last-of-type": {
            padding: theme.spacing(0.5, 1),
        },
    }),
    ...(isPlaceholder && {
        color: theme.palette.action.active,
        "&:disabled": {
            color: theme.palette.text.disabled,
        },
    }),
}));

const ArrowTab = styled(DateTab)(({ theme }) => ({
    color: theme.palette.action.active,
    padding: theme.spacing(0.5, 0),
    [`&.${tabClasses.fullWidth}`]: {
        flexGrow: 0,
        flexBasis: "auto",
    },
}));

export interface ICalculationTimerangeSelectProps {
    granularity: MeasureCalculationGranularity;
    fiscalYearStart: number;
    start: Moment | null;
    onStartChanged?: (newStart: Moment) => void;
    end: Moment | null;
    onEndChanged?: (newEnd: Moment) => void;
    translate: TFunction;
    maxStart?: Moment;
    minStart?: Moment;
    minEnd?: Moment;
    maxEnd?: Moment;
    disabled?: boolean;
    dense?: boolean;
    className?: string;
}

// use string enum to match requirements of Tabs/Tab components
// if those values are numbers, they are interpreted as index numbers on the tabs
enum Selection {
    Start = "Start",
    End = "End",
}

const MAX_MOMENT = moment(MaxYear - 1, "YYYY");
const MIN_MOMENT = moment(MinYear, "YYYY");

const CalculationTimerangeSelect = React.forwardRef<HTMLDivElement, ICalculationTimerangeSelectProps>(
    (
        {
            granularity,
            fiscalYearStart,
            start,
            end,
            onStartChanged,
            onEndChanged,
            translate,
            minEnd,
            maxStart,
            disabled = false,
            dense = false,
            className,
            minStart = MIN_MOMENT,
            maxEnd = MAX_MOMENT,
        },
        ref,
    ) => {
        const [tabSelection, setTabSelection] = useState<Selection | null>(null);

        // anchor element of the open dialog, may be the the button of start or end date
        const [anchorEl, setAnchorEl] = useState<Element | null>(null);

        // keep refs to start/end buttons, so they can be selected outside of a click handler
        const startRef = React.useRef<HTMLDivElement | null>(null);
        const endRef = React.useRef<HTMLDivElement | null>(null);

        // Use the same labels as placeholder as shown in the granularity selector
        const placeholder = translate(`${translationKeys.VDLANG_MEASURE_CALCULATION_GRANULARITY_PLACEHOLDER}.${granularity}`);
        const startLabel =
            start != null ? formatFiscalUnit(calendarToFiscal(start, fiscalYearStart, granularity), granularity, translate) : placeholder;
        const endLabel =
            end != null ? formatFiscalUnit(calendarToFiscal(end, fiscalYearStart, granularity), granularity, translate) : placeholder;

        const closeDialog = () => {
            setTabSelection(null);
            setAnchorEl(null);
        };

        const handleDateChange = (newValue: Moment) => {
            tabSelection === Selection.Start && onStartChanged?.(newValue);
            // DatePicker will keep the day that is given (sel feb -> day=28. fix for EOM)
            tabSelection === Selection.End && onEndChanged?.(newValue.endOf("month"));
            if (start == null && end == null) {
                // If both have been null, and one selected, automatically open the other dialog
                const newSelection = tabSelection === Selection.Start ? Selection.End : Selection.Start;
                setTabSelection(newSelection);
                newSelection === Selection.Start && setAnchorEl(startRef.current);
                newSelection === Selection.End && setAnchorEl(endRef.current);
            } else {
                closeDialog();
            }
        };

        const updateTabSelection = (event: React.SyntheticEvent, value: Selection) => {
            setTabSelection(value);
            if ("currentTarget" in event) {
                setAnchorEl(event.currentTarget); // Tabs' onChange has wrong generics: {} Should be Element/HTMLElement
            }
        };

        const selectedDate = tabSelection === Selection.Start ? start : end;

        // if no minEnd provided, restrict at least to be not earlier than start
        const min = tabSelection === Selection.End ? (minEnd ?? start) : undefined;

        // if no maxStart provided, restrict at least to be not later than end
        const max = tabSelection === Selection.Start ? (maxStart ?? end) : undefined;

        return (
            <>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={closeDialog}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <CalculationTimerangeDialog
                        defaultValue={selectedDate}
                        granularity={granularity}
                        updateValue={handleDateChange}
                        min={min ?? MIN_MOMENT}
                        max={max ?? MAX_MOMENT}
                        fiscalYearStart={fiscalYearStart}
                        translate={translate}
                    />
                </Popover>
                <Tooltip title={translate(`${translationKeys.VDLANG_CALCULATION_TABLE_TOOLTIP}.time_range_select`)}>
                    <RootTabs
                        className={className}
                        value={tabSelection ?? false}
                        onChange={updateTabSelection}
                        textColor="inherit"
                        variant={dense ? "standard" : "fullWidth"}
                        ref={ref}
                        component="div"
                    >
                        <DateTab
                            aria-label={translate(translationKeys.VDLANG_START_DATE)}
                            ref={startRef}
                            disabled={disabled}
                            dense={dense}
                            isPlaceholder={start == null}
                            label={startLabel}
                            value={Selection.Start}
                        />
                        <ArrowTab disabled dense={dense} label={<TrendingFlatIcon />} />
                        <DateTab
                            aria-label={translate(translationKeys.VDLANG_END_DATE)}
                            ref={endRef}
                            disabled={disabled}
                            dense={dense}
                            isPlaceholder={end == null}
                            label={endLabel}
                            value={Selection.End}
                        />
                    </RootTabs>
                </Tooltip>
            </>
        );
    },
);

export default CalculationTimerangeSelect;
